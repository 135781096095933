import logo from "../../images/P&S-platform.png";

const Brand = () => {
  return (
    <img src={logo} alt="Ag Parts Sellout logo" className="logo" />
    // <div className="brand">
    //   PARTS & SERVICE
    //   <br />
    //   <span>PLATFORM</span>
    // </div>
  );
};

export default Brand;
